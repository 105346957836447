import React from 'react'
import EmbededVideos from './EmbededVideos'

const LecturesContainer = () => {
  return (
    <div>
        <EmbededVideos/>
    </div>
  )
}

export default LecturesContainer